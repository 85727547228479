<template>
  <div class="gate-machine-container">
    <ContentTitle>{{ tableTitle }}</ContentTitle>
    <Wfilter
      :filterConfig="filterConfig"
      @openGateMachineDialog="openGateMachineDialog"
      @handleSearch="handleSearch"
      @changeParking="changeParking"
      @changeProject="changeProject"
      @print="handlePrint"
      @export="handleExport"
    ></Wfilter>
    <Wtable
      :columns="columns"
      :pagination="pagination"
      :tableData="tableData"
      @sizeChange="sizeChange"
      @currentChange="currentChange"
      @update="openGateMachineDialog"
    >
      <template #status="{ row }">
        <el-switch
          v-model="row.status"
          @click="changeStatus(row)"
          inline-prompt
          active-text="启用"
          inactive-text="关闭"
          :disabled="canChangeStatus == 0 ? true : false"
        >
        </el-switch>
      </template>
    </Wtable>
    <GateMachineDialog
      ref="gateMachineDialog"
      @submit="reload"
      :needParking="true"
    ></GateMachineDialog>
  </div>
</template>
<script>
import { ref, reactive, onMounted, watch, onUnmounted, computed } from "vue";
import { ElMessage } from "element-plus";
import { useStore } from "vuex";
import {
  print,
  exportExcel,
  getTopBtnAuth,
  getBtnAuth,
} from "@/utils/common.js";
import Wfilter from "@/components/wFilter/wFilter.vue";
import Wtable from "@/components/wTable/wTable.vue";
import ContentTitle from "@/components/contentTitle/contentTitle.vue";
import GateMachineDialog from "./components/GateMachineDialog.vue";
import API from "@/plugins/api.js";
import config from "./gateMachine.json";
export default {
  components: {
    Wfilter,
    Wtable,
    ContentTitle,
    GateMachineDialog,
  },
  setup() {
    const filterConfig = reactive(config.filterConfig);
    const columns = reactive(config.tableConfig.list);
    const defaultBtns = reactive(config.tableConfig.btns);
    const tableData = ref([]);
    const pagination = config.tableConfig.pagination;
    const page = ref(1);
    const keyword = ref("");
    const projectId = ref("");
    const parkingId = ref("");
    const tableTitle = ref("闸机管理");
    const store = useStore();
    const rightBtn = computed(() => store.state.saasConfig.rightBtn); //右侧页面的所有权限信息
    // 获取权限按钮
    columns[columns.length - 1].btns = getBtnAuth(rightBtn.value, defaultBtns);
    filterConfig.btns = getTopBtnAuth(rightBtn.value, filterConfig.btns);
    const canChangeStatus = ref(0);

    // 获取权限按钮

    if (rightBtn.value && rightBtn.value.length) {
      rightBtn.value.forEach((el) => {
        if (el.api_menu_token == "Btn_m0IN2JiBUwiwEcpDpH2QYakNY94Z") {
          //修改状态
          canChangeStatus.value = 1;
        }
      });
    }
    function fetchData(parmas) {
      let lastParmas = {
        ...parmas,
        page: page.value,
        keyword: keyword.value,
        project_id: projectId.value,
        carpark_id: parkingId.value,
        limit: pagination.pageSize,
      };
      API.getGateMachine(lastParmas).then((res) => {
        if (res.data.length) {
          res.data.forEach((el) => {
            el.status == 1 ? (el.status = true) : (el.status = false);
          });
        }
        pagination.total = res.total;
        tableData.value = res.data;
      });
    }

    onMounted(() => {
      API.getProjectOptions({}).then((res) => {
        if (res.length) {
          filterConfig.selects[0].mapData = res;
          filterConfig.selects[0].value = res[0].id;
          projectId.value = res[0].id;
        }
      });
    });
    watch(
      () => projectId.value,
      (v) => {
        if (v) {
          parkingId.value = "";
          filterConfig.selects[1].disabled = false;
          API.getParkingOptions({ project_id: v }).then((res) => {
            filterConfig.selects[1].mapData = res;
          });
          fetchData();
        }
      },
      {
        deep: true,
        immediate: true,
      }
    );
    const currentChange = (v) => {
      page.value = v;
      fetchData();
    };
    const sizeChange = (v) => {
      pagination.pageSize = v;
      fetchData();
    };
    const changeProject = (pro) => {
      projectId.value = pro.value;
    };
    const changeParking = (pro) => {
      parkingId.value = pro.value;
      fetchData();
    };
    const handleSearch = (kwd) => {
      keyword.value = kwd;
      fetchData();
    };
    const gateMachineDialog = ref(null);
    function openGateMachineDialog(data) {
      if (data && data.row) {
        API.getGateMachineDetail({ id: data.row.id }).then((res) => {
          gateMachineDialog.value.openDialog(res);
        });
      } else {
        gateMachineDialog.value.openDialog();
      }
    }
    function changeStatus(data) {
      if (canChangeStatus.value) {
        const status = data.status == true ? 1 : 0;
        let parmas = {
          id: data.id,
          status: status,
        };
        API.editGateMachineStatus(parmas).then(() => {
          ElMessage.success({
            message: "操作成功！",
          });
          fetchData();
        });
      } else {
        ElMessage.warning({
          message: "暂无权限操作！",
        });
        return false;
      }
    }
    function reload(data) {
      const parmas = {
        sn: data.sn,
        name: data.name,
        carpark_id: data.carpark_id,
        resources: data.resources,
        status: data.status,
        gate_position_id: data.gate_position_id,
        gate_type_id: data.gate_type_id,
        charge: data.charge,
      };
      if (data.id) {
        // 编辑
        API.editGateMachine({
          ...parmas,
          id: data.id,
        }).then(() => {
          ElMessage.success({
            message: "操作成功",
          });
          gateMachineDialog.value.closeDialog();
          fetchData();
        });
      } else {
        // 新增
        API.addGateMachine(parmas).then(() => {
          ElMessage.success({
            message: "操作成功",
          });
          gateMachineDialog.value.closeDialog();
          fetchData();
        });
      }
    }
    const propertiesConfig = ref([
      { field: "id", displayName: "序号" },
      { field: "car_park_name", displayName: "所属车场" },
      { field: "name", displayName: "闸机名称" },
      { field: "online_status_text", displayName: "在线状态" },
      { field: "sn", displayName: "控制机序列" },
      { field: "status_text", displayName: "启用状态" },
      { field: "position_name", displayName: "位置" },
    ]);
    function handlePrint() {
      print(tableTitle.value, tableData.value, propertiesConfig.value);
    }
    function handleExport() {
      try {
        exportExcel(tableTitle.value, tableData.value, propertiesConfig.value);
        ElMessage.success({
          message: "导出成功！",
        });
      } catch (e) {
        ElMessage.error({
          message: "导出失败！",
        });
      }
    }
    onUnmounted(() => {
      filterConfig.selects[1].value = "";
    });
    return {
      tableData,
      columns,
      pagination,
      currentChange,
      openGateMachineDialog,
      filterConfig,
      changeParking,
      handleSearch,
      gateMachineDialog,
      reload,
      sizeChange,
      changeProject,
      changeStatus,
      handlePrint,
      handleExport,
      tableTitle,
      canChangeStatus,
    };
  },
};
</script>

<style lang='scss'>
.gate-machine-container {
}
</style>